<template>
        <div class="row justify-content-center">
            <div class="col-lg-5 col-md-7">
                <div class="card bg-secondary shadow border-0">
                    <div class="card-body px-lg-5 py-lg-5" align="center">
                        <img src="logo.png" class="mb-3" :style="{'height': '40px', 'width': 'auto'}">
                        <div class="text-center text-muted mb-4">
                            <small>Киоск машины кодоо оруулна уу</small>
                        </div>
                        <form role="form" @submit.prevent="login">
                            <!-- <base-input class="input-group-alternative mb-3"
                                        placeholder="Имэйл"
                                        addon-left-icon="ni ni-email-83"
                                        v-model="kioskId">
                            </base-input> -->
                            <el-input placeholder="Киоск машины код" suffix-icon="el-icon-right" clearable="" v-model="kioskId"> </el-input>
                            <!-- <el-button type="submit" style="margin-top: 10px; width: 100%;">Нэврэх</el-button> -->
                            <base-button native-type="submit" type="primary" class="my-2" style="width: 100%;">Нэвтрэх</base-button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
</template>
<script>
  export default {
    name: 'login',
    data() {
      return {
        kioskId: ''
      }
    },
    // mounted() {
    //     var kiosk = localStorage.getItem('kiosk-id');
    //     if(kiosk != undefined && kiosk != null) {
    //         this.$router.push('/slide');
    //     }
    // },
    methods: {
        login() {
            var rts = this;
            this.$axios({
                method: 'post',
                url: '/kiosk/login',
                headers: {},
                data: {
                    kioskId: this.kioskId
                }
            }).then(data => {
                if(data.data.result == 'failed') {
                    this.$notify({
                            title: 'Амжилтгүй',
                            message: 'Төхөөрөмжийн код олдсонгүй',
                            type: 'danger'
                        });
                } else {
                    localStorage['kiosk-id'] = this.kioskId;
                    this.$notify({
                            title: 'Амжилттай',
                            message: `Нэвтэрлээ`,
                            type: 'success'
                        });
                    rts.$router.push('/slide');
                }
            });
        }
    }
  }
</script>
<style>
</style>
