<style scoped>
.search {
    width: 30%;
}
@media only screen and (max-width: 600px) {
  .search {
    width: 100%;
  }
}

.form__label {
  font-family: 'Roboto', sans-serif;
  font-size: 1.2rem;
  margin-left: 2rem;
  margin-top: 0.7rem;
  display: block;
  transition: all 0.3s;
  transform: translateY(0rem);
}

.form__input {
  font-family: 'Roboto', sans-serif;
  color: #333;
  font-size: 1.2rem;
	margin: 0 auto;
  padding: 1.5rem 2rem;
  border-radius: 0.2rem;
  background-color: rgb(255, 255, 255);
  border: none;
  width: 90%;
  display: block;
  border-bottom: 0.3rem solid transparent;
  transition: all 0.3s;
}

.form__input:placeholder-shown + .form__label {
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(-4rem);
  transform: translateY(-4rem);
}

.mt-10 {
    margin-top: 10px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 19px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  left: 1px;
  bottom: 1px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #13ce66;
}

input:focus + .slider {
  box-shadow: 0 0 1px #13ce66;
}

input:checked + .slider:before {
  -webkit-transform: translateX(21px);
  -ms-transform: translateX(21px);
  transform: translateX(21px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
<template>
    <div v-loading.fullscreen.lock="loading">

        <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8" align="right">
            <el-button type="primary" @click="modals.photo = true" size="mini">Текст нэмэх</el-button>
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col">
                    <div class="card shadow border-0 p-5">
                        <!-- <div align="right">
                            <form>
                                <el-input placeholder="Огноо ж. 2021-06-24" v-model="search" clearable class="search"> </el-input>
                            </form>
                        </div> -->
                        <div class="table-responsive mt-2">
                                <base-table class="table align-items-center table-flush thead-light"
                                            tbody-classes="list"
                                            :data="photos">
                                <template slot="columns">
                                    <th :style="{'background': '#F6F9FC'}">#Дугаар</th>
                                    <th :style="{'background': '#F6F9FC'}">Текст</th>
                                    <th :style="{'background': '#F6F9FC'}">Эхлэх огноо</th>
                                    <th :style="{'background': '#F6F9FC'}">Дуусах огноо</th>
                                    <th :style="{'background': '#F6F9FC'}">Бүртгэсэн ажилтан</th>
                                    <th :style="{'background': '#F6F9FC'}"></th>
                                </template>
                                <template slot-scope="{row}">
                                    <th scope="row">
                                        <div class="media align-items-center">
                                            <div class="media-body">
                                                {{row.id}}
                                            </div>
                                        </div>
                                    </th>
                                    <th scope="row">
                                        <div class="media align-items-center">
                                            <div class="media-body">
                                                <!-- <el-image 
                                                    style="width: 40px; height: auto"
                                                    :src="$appUrl+'/contents/image/slide/'+row.name" 
                                                    :preview-src-list="[$appUrl+'/contents/image/slide/'+row.name]">
                                                </el-image> -->
                                                {{row.text}}
                                            </div>
                                        </div>
                                    </th>
                                    <td scope="row">
                                        <div class="media align-items-center">
                                            <div class="media-body">
                                                <span class="name mb-0 text-sm">{{row.created_at}}</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td scope="row" v-if="!row.isChange">
                                        <div class="media align-items-center">
                                            <div class="media-body">
                                                <span class="name mb-0 text-sm">{{row.end_at}}</span> 
                                                <el-tooltip class="item" effect="dark" content="Дуусах хугацааг шинэчлэх" placement="top-end">
                                                    <el-button type="primary" icon="el-icon-edit" size="mini" @click="row.isChange = true" style="margin-left: 10px;" circle></el-button>
                                                </el-tooltip>
                                            </div>
                                        </div>
                                    </td>
                                    <td scope="row" v-else-if="row.isChange">
                                        <div class="media align-items-center">
                                            <div class="media-body">
                                                <el-date-picker
                                                    style="max-width: 150px;"
                                                    v-model="row.changed_at"
                                                    type="date"
                                                    placeholder="Огноогоо сонгоно уу">
                                                </el-date-picker>

                                                <el-button type="success" icon="el-icon-check" size="mini" style="margin-left: 10px;" @click="saveChangedDate(row)" circle></el-button>
                                                <el-button type="danger" icon="el-icon-close" size="mini" @click="hideChangeDate(row)" style="margin-left: 10px;" circle></el-button>
                                            </div>
                                        </div>
                                    </td>
                                    <td scope="row">
                                        <div class="media align-items-center">
                                            <div class="media-body">
                                                <span class="name mb-0 text-sm">-</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td align="right">
                                        <el-tooltip class="item" effect="dark" content="Киоск дээр нэмэх" placement="top">
                                            <base-button type="warning" @click="selectVideo(row.id)" size="sm"><span class="ni ni-cloud-upload-96"></span></base-button>
                                        </el-tooltip>
                                        <el-tooltip class="item" effect="dark" content="Устгах" placement="top">
                                            <base-button type="danger" @click="deletePhoto(row.id)" size="sm"><span class="ni ni-fat-remove"></span></base-button>
                                        </el-tooltip>
                                    </td>
                                </template>
                                </base-table>
                        </div>
                    </div>
                    <!-- <span class="mt-3" @click="getProduct">
                        <base-pagination :page-count="pagination" class="mt-3" v-model="page"></base-pagination>
                    </span> -->
                </div>
            </div>
        </div>

        <modal :show.sync="modals.addVideo">
            <h6 slot="header" class="modal-title" id="modal-title-default">Текст оруулах</h6>
              <div class="row" style="padding: 10px;">
                <!-- <el-input placeholder="Киоскын код" maxlength="20" v-model="addKioskCode" clearable=""> </el-input> -->
                <el-checkbox :indeterminate="kiosks.isIndeterminate" v-model="kiosks.checkall" @change="handleCheckAllChange">Check all</el-checkbox>
                <div style="margin: 15px 0;"></div>
                <el-checkbox-group v-model="kiosks.checked" @change="handleCheckedCitiesChange" style="display: grid; grid-template-columns: 1fr 1fr 1fr;">
                    <el-checkbox v-for="(item, index) in kiosks.list" :label="item.code" :key="index" style="width: 100%;">
                        <span style="margin-left: 30px;">{{item.code}}</span> 
                        <!-- <span style="margin-left: 50px;">{{item.districtname}}, {{item.address}}</span> -->
                    </el-checkbox>
                </el-checkbox-group>
              </div>
              <template slot="footer">
                    <base-button type="link" class="ml-auto" @click="modals.addVideo = false">Гарах</base-button>
                    <base-button type="primary" @click="saveVideo">Хадгалах</base-button>
            </template>
        </modal>

        <modal :show.sync="modals.photo">
            <h6 slot="header" class="modal-title" id="modal-title-default">Текст оруулах</h6>
              <div class="row">
                <div class="col-md-12" :style="{'max-height': '400px', 'overflow': 'auto'}">
                    <div class="block">
                        <el-date-picker style="width: 100%;"
                            v-model="currentText.created_at"
                            type="date"
                            placeholder="Эхлэх огноо">
                        </el-date-picker>
                    </div>
                    <div class="block mt-10">
                        <el-date-picker style="width: 100%;"
                            v-model="currentText.end_at"
                            type="date"
                            placeholder="Дуусах огноо">
                        </el-date-picker>
                    </div>
                    <div class="block mt-10">
                        <el-input type="text" placeholder="Текст" v-model="currentText.text" maxlength="200" show-word-limit></el-input>
                    </div>
                </div>
              </div>

              <template slot="footer">
                <base-button type="link" class="ml-auto" @click="modals.photo = false">Гарах</base-button>
                <base-button type="primary" @click="uploadText">Хадгалах</base-button>
          </template>
        </modal>
    </div>
</template>
<script>
  export default {
    data() {
        return {
            photos: [],
            kiosks: {
                checkall: false,
                checked: [],
                list: [],
                isIndeterminate: false
            },
            search: '',
            loading: false,
            modals: {
                photo: false,
                addVideo: false                
            },
            currentText: {
                text: '',
                created_at: '',
                end_at: '',      
            },
            addKioskCode: '',
            selectedVideo: ''
        }
    },
    mounted() {
      this.getText();
      this.getKiosks();
    },
    methods: {
        handleCheckAllChange(val) {
            if(val) {
                this.kiosks.list.forEach(eleme => {
                    this.kiosks.checked.push(eleme.code);
                });
            } else {
                this.kiosks.checked = [];
            }
            this.kiosks.isIndeterminate = !val;
        },
        handleCheckedCitiesChange(value) {
            let checkedCount = value.length;
            this.kiosks.checkall = checkedCount === this.kiosks.list.length;
            this.kiosks.isIndeterminate = checkedCount > 0 && checkedCount < this.kiosks.list.length;
        },
        getKiosks() {
            var rts = this;
            this.loading = true;
            var token = localStorage.getItem('token');
            rts.$axios({
                method: 'POST',
                url: this.$appUrl + '/user/get-all-kiosk',
                headers: {
                    "Authorization": `Bearer ${token}`
                },
            }).then(data => {
                rts.loading = false;
                if(data.data.result == 'success') {
                    rts.kiosks.list = data.data.kiosk;
                    console.log(data.data.kiosk);
                }
            }).catch(error => {
				rts.loading = false;
				console.log(error);
			});
        },
        formatDate(date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) 
                month = '0' + month;
            if (day.length < 2) 
                day = '0' + day;

            return [year, month, day].join('-');
        },
        saveChangedDate(row) {
            var rts = this;
            var token = localStorage.getItem('token');
            this.loading = true;
            this.$axios({
                method: 'POST',
                url: this.$appUrl + '/user/update-marquee-end-at',
                headers: {
                    "Authorization": `Bearer ${token}`
                },
                data: {
                    row
                }
            }).then(data => {
                rts.loading = false;
                if(data.data.result == 200) {
                    row.end_at = rts.formatDate(row.changed_at);
                    row.active = 1;
                    row.isChange = false;
                    rts.$notify({
                        title: 'Амжилттай',
                        message: `Огноо шинэчлэгдлээ`,
                        type: 'success'
                    });
                }
            });
        },
        hideChangeDate(row) {
            row.changed_at = row.end_at;
            row.isChange = false;
        },
        selectVideo(id) {
            this.selectedVideo = id;
            this.modals.addVideo = true;
        },
        saveVideo() {
            var rts = this;
                this.loading = true;
                var token = localStorage.getItem('token');
                rts.$axios({
                    method: 'POST',
                    url: this.$appUrl + '/user/add-kiosk-text',
                    headers: {
                        "Authorization": `Bearer ${token}`
                    },
                    data: {
                        kiosk: this.kiosks.checked, 
                        id: this.selectedVideo
                    }
                }).then(data => {
                    rts.loading = false;
                    if(data.data.result == 'success') {
                        rts.$notify({
                            title: 'Амжилттай',
                            message: `Бүртгэгдлээ`,
                            type: 'success'
                        });
                    } else if(data.data.result == 'duplicate') {
                        rts.$notify({
                            title: 'Амжилтгүй',
                            message: `Аль хэдийн бүртгэгдсэн`,
                            type: 'danger'
                        });
                    } else if(data.data.result == 'failed') {
                        rts.$notify({
                            title: 'Амжилтгүй',
                            message: `Киоск олдсонгүй`,
                            type: 'danger'
                        });
                    }
                }).catch(error => {
                    rts.loading = false;
                    console.log(error);
                });
        },
        deletePhoto(id) {
            this.$confirm('Устгахдаа итгэлтэй байна уу?', 'Санамж', {
                confirmButtonText: 'Тийм',
                cancelButtonText: 'Болих',
                type: 'warning'
            }).then(() => {
                var rts = this;
                this.loading = true;
                var token = localStorage.getItem('token');
                rts.$axios({
                    method: 'POST',
                    url: this.$appUrl + '/user/delete-marquee',
                    headers: {
                        "Authorization": `Bearer ${token}`
                    },
                    data: {
                        id
                    }
                }).then(data => {
                    rts.loading = false;
                    if(data.data.result == 'success') {
                        this.photos.forEach((el, index) => {
                            if(el.id == id) {
                                rts.photos.splice(index, 1);
                            }
                        });
                    }
                }).catch(error => {
                    rts.loading = false;
                    console.log(error);
                });
            });
        },
        getText() {
            var rts = this;
            this.loading = true;
            var token = localStorage.getItem('token');
            rts.$axios({
                method: 'POST',
                url: this.$appUrl + '/user/get-marquee',
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            }).then(data => {
                rts.loading = false;
                if(data.data.result == 'success') {
                    rts.photos = data.data.marquee;
                }
            }).catch(error => {
				rts.loading = false;
				console.log(error);
			});
        },
        uploadText() {
            var rts = this;
            this.loading = true;
            var token = localStorage.getItem('token');
            rts.$axios({
                method: 'POST',
                url: this.$appUrl + '/user/add-marquee',
                headers: {
                    "Authorization": `Bearer ${token}`
                },
                data: {
                    text: this.currentText
                }
            }).then(data => {
                rts.loading = false;
                if(data.data.result == 'success') {
                    rts.modals.photo = false;
                    rts.getText();
                }
            }).catch(error => {
				rts.loading = false;
				console.log(error);
			});
        }
    }
  }
</script>
