<template>
    <div class="swiper-container" style="position: relative; background: #fff;">
        <el-button circle @click="toggleMute" style="z-index: 50; position: fixed; top: 20px; left: 20px;"><img :src="muteImg" style="width: 40px; height: 40px;"></el-button>
        <video id="homevideo" width="100%" ref="homevideo" webkit-playsinline="true" playsinline="true" style="height: 608px; position: relative;" :muted="muted">
            <source :src="currentVideo.src" :type="currentVideo.type">
        </video>
        <div>
            <el-carousel indicator-position="none" arrow="never" @change="FixInterval" :interval="interval" style="height: 1200px;">
                <el-carousel-item v-for="(i, index) in photos" :key="index" style="height: 1200px;">
                    <img
                    class="item__image"
                    :src="'/contents/image/slide/' + i.name"
                    alt=""
                    />
                </el-carousel-item>
            </el-carousel>
        </div>
        <marquee style="position: fixed; bottom: 15px; font-size: 40px;">SAMPLE TEXT MARQUEE</marquee>
        <div style="position: fixed; bottom: 15px; left: 0px; padding-left: 20px; line-height: normal; z-index: 50; background: #fff;">
            <span style="font-size: 45px; font-weight: 700;">{{date.hour}} : {{date.min}} : {{date.second}}</span><br>
            <span style="font-size: 25px; font-weight: 700;">{{date.date}}</span>
        </div>
        <img v-if="weather.icon != ''" :src="'https://openweathermap.org/img/wn/' + weather.icon +'@2x.png'" style="position: fixed; bottom: 35px; right: 80px; width: 80px; height: auto; z-index: 51;"/>
        <div style="position: fixed; bottom: 15px; right: 0px; padding-right: 20px; line-height: normal; z-index: 50; background: #fff;" align="right">
             <span style="font-size: 50px; font-weight: 700;">{{weather.temp}}°</span><br>
             <span style="font-size: 25px; font-weight: 700;">Ulaanbaatar</span>
        </div>
    </div>
</template>
<script>
var timer = null;
var d = null, w = null;
export default {
    data() {
        return {
            btnType: 'danger',
            muteImg: 'mute.png',
            kioskId: '',
            muted: true,
            photos: [],
            interval: 15000,
            videos: [],
            videoIndex: 0,
            currentVideo: {
                src: '',
                type: ''
            },
            VideoInterval: 0,
            date: {
                hour: '',
                min: '',
                second: '',
                date: ''
            },
            weather: {
                temp: '',
                icon: '',
                desc: ''
            }
        }
    },
    beforeRouteLeave (to, from , next) {
        clearInterval(timer);
        clearInterval(d);
        clearInterval(w);
        next();
    },
    mounted() {
        var kiosk = localStorage.getItem('kiosk-id');
        if(kiosk == undefined || kiosk == null) {
            this.$router.push('/auth-kiosk');
        } else {
            this.kioskId = kiosk;
            this.getPhotos();
        }
        this.$refs.homevideo.addEventListener('ended', this.HandleVideo);
        timer = setInterval(this.checkPlayingVideo, 60000);
        d = setInterval(this.time, 1000);
        this.getWeather();
        w = setInterval(this.getWeather, 3600000);
    },
    methods: {
        toggleMute() {
            this.muted = !this.muted;
            if(this.muted) {
                this.muteImg = 'mute.png';
            } else {
                this.muteImg = 'unmute.png';
            }
        },
        time() {
            var dt = new Date();
            this.date.hour = ((dt.getHours() > 9) ? dt.getHours() : ('0' + dt.getHours()));
            this.date.min = ((dt.getMinutes() > 9) ? dt.getMinutes() : ('0' + dt.getMinutes()));
            this.date.second = ((dt.getSeconds() > 9) ? dt.getSeconds() : ('0' + dt.getSeconds()));
            this.date.date = dt.toDateString();
        },
        checkPlayingVideo() {
            if(this.VideoInterval > 10) {
                location.reload();
            } else {
                this.VideoInterval++;
            }
        },
        HandleVideo() {
            this.VideoInterval = 0;
            if(this.videos.length == this.videoIndex + 1) {
                this.videoIndex = 0;
            } else {
                this.videoIndex++;
            }
            this.currentVideo = this.videos[this.videoIndex];
            this.$refs.homevideo.load();
            this.$refs.homevideo.play();
        },
        getWeather() {
            var rts = this;
            this.$axios({
                method: 'GET',
                url: 'https://api.openweathermap.org/data/2.5/weather?q=Ulaanbaatar,MN&units=metric&appid=af5b19ba73d013664a2eeabf76ea54c3',
                headers: { 
                    'Content-Type': 'application/json'
                }
            }).then(data => {
                rts.weather.temp = parseInt(data.data.main.temp);
                rts.weather.desc = data.data.weather[0].description;
                rts.weather.icon = data.data.weather[0].icon;
            }).catch(error => {
				console.log(error);
			});
        },
        FixInterval(index) {
            this.interval = this.photos[index].second * 1000;
        },
        playM() {
            this.currentVideo = this.videos[this.videoIndex];
            // console.log(this.currentVideo);
            this.$refs.homevideo.load();
            this.$refs.homevideo.play();   
        },
        getPhotos() {
            var rts = this;
            var token = localStorage.getItem('token');
            rts.$axios({
                method: 'POST',
                url: this.$appUrl + '/kiosk/get-photo',
                headers: {
                    "Authorization": `Bearer ${token}`
                },
                data: {
                    id: this.kioskId
                }
            }).then(data => {
                if(data.data.result == 'success') {
                    rts.photos = data.data.photos;
                    rts.interval = data.data.photos[0].second * 1000;
                    
                    data.data.videos.forEach(el => {
                        rts.videos.push({src: '/contents/video/' + el.name, type: el.extn});
                    });

                    rts.playM();
                }
            }).catch(error => {
				console.log(error);
			});
        }
    }
}
</script>
<style scoped>
.item {
  position: relative;
  height: 100%;
}
.item__image {
  width: 100%;
  height: auto;
  object-fit: cover;
}
.swiper-container {
    background: #fff;
}
</style>